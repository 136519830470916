<template>
  <div class="page-content">
    <div class="container-fluid min-vh-100">
      <Breadcrumb
        :pgTitle="$t('nav.reports')"
        :pgIcon="'bx bx-line-chart'"
        :refs="refs"
        :addNew="addNew"
      >
      </Breadcrumb>

      <DataTable
        :refs="refs"
        :hasReportDropDown="true"
        :colspan="9"
        :hasSearchFilter="true"
        :hasDateFromFilter="true"
        :hasDateToFilter="true"
        :hasTimeFromFilter="true"
        :hasTimeToFilter="true"
        :hasLecturerFilter="true"
        :hasHashId="true"
        :hasMultiSelect="false"
        :hasBulkAction="true"
        :HasExport="true"
        :hasShowEntries="true"
        :lblSearch="$t('app.search_by_name')"
        :fields="{
          id: 'id',
          title: 'title',
          price: 'price',
          time: 'time',
          date: 'date',
        }"
        :hasUser="true"
        :image="$t('view.user')"
        :image_style="'width: 40px;height: 40px;border-radius: 50%'"
        :hasModule="true"
        :module="$t('app.side')"
        :hasDetail="true"
        :detail="$t('app.detail')"
        :hasPrice="true"
        :price="$t('app.price')"
        :hasPaid="true"
        :hasStatus="true"
        :hasAccept="true"
        :hasDecline="true"
        :hasDelayed="true"
        :hasApproved="true"
        :hasTime="false"
        :hasDate="true"
        :hasCallWhatsapp="true"
        :hasActions="true"
        :hasProgress="true"
        :hasNoEdit="true"
        :hasNoDelete="true"
        :actionable="true"
        :sorDataTable="true"
      >
      </DataTable>
    </div>
  </div>
</template>

<script>
export default {
  name: "List",
  components: {
    Breadcrumb: () => import("@/components/Breadcrumb.vue"),
    DataTable: () => import("@/components/DataTable.vue"),
  },
  data() {
    return {
      //
      addNew: false,
      refs: "reports",
    };
  },
  watch: {
    //
  },
  mounted() {
    //
  },
  created() {
    //
  },
  methods: {
    //
  },
};
</script>
