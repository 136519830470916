var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-content"},[_c('div',{staticClass:"container-fluid min-vh-100"},[_c('Breadcrumb',{attrs:{"pgTitle":_vm.$t('nav.reports'),"pgIcon":'bx bx-line-chart',"refs":_vm.refs,"addNew":_vm.addNew}}),_c('DataTable',{attrs:{"refs":_vm.refs,"hasReportDropDown":true,"colspan":9,"hasSearchFilter":true,"hasDateFromFilter":true,"hasDateToFilter":true,"hasTimeFromFilter":true,"hasTimeToFilter":true,"hasLecturerFilter":true,"hasHashId":true,"hasMultiSelect":false,"hasBulkAction":true,"HasExport":true,"hasShowEntries":true,"lblSearch":_vm.$t('app.search_by_name'),"fields":{
        id: 'id',
        title: 'title',
        price: 'price',
        time: 'time',
        date: 'date',
      },"hasUser":true,"image":_vm.$t('view.user'),"image_style":'width: 40px;height: 40px;border-radius: 50%',"hasModule":true,"module":_vm.$t('app.side'),"hasDetail":true,"detail":_vm.$t('app.detail'),"hasPrice":true,"price":_vm.$t('app.price'),"hasPaid":true,"hasStatus":true,"hasAccept":true,"hasDecline":true,"hasDelayed":true,"hasApproved":true,"hasTime":false,"hasDate":true,"hasCallWhatsapp":true,"hasActions":true,"hasProgress":true,"hasNoEdit":true,"hasNoDelete":true,"actionable":true,"sorDataTable":true}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }